import {Layout, Hero} from "components/layout"
import SEO from "components/seo"
import { Link, Link as LinkBase} from "gatsby";
import { Section, Typography, Button, Container, Row, SectionTitle } from "components/ui"
import React from "react"
import * as Sections from 'components/sections'
import * as Ui from "components/ui"
const AffiliatePage = () => (
  <Layout>
    <SEO refKey="backupsheep" />
    <Ui.Section color="">
      <Ui.Container>
        <Ui.Row>
          <div className="col-md-12 text-center">
            <SectionTitle
              tag="h1"
              align="center"
              title={(<>Looking for BackupSheep Alternatives?</>)}
              subTitle={(<>
                BackupSheep is closing its doors on November 1st.<br/>
                Discover why SimpleBackups is your seamless transition to a reliable backup solution.<br/>
                Join today and benefit from 30% discount on your first 3 months.

                <div className="flex gap-6 flex-wrap items-center justify-center mt-12">
                  <Button
                    size="large"
                    href="https://my.simplebackups.com/register?ref=backupheep"
                  >
                    Get Started
                  </Button>
                  <Button
                    size="large"
                    href="#"
                    color="primary-border"
                    onClick={() => window.Intercom('showNewMessage')}
                  >
                    Any questions?
                  </Button>
                </div>

                <div className="  mx-auto mt-16">
                  <Ui.Image className="" filename="simplebackups-app-dasbhoard-compiled.png" alt="SimpleBackups dashboard, all your websites backups in one place"/>
                </div>

                <div className="mt-16">
                  <Sections.JoinBrandsSection />
                </div>
              </>)}
            />
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="alt" >
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center"><span>All your backups in one place</span></Ui.Typography>
          <p className="mb-6 text-center">Don't leave any data unprotected. We'll help you mirate your BackupSheep's backups smoothly.</p>
          <div className="grid grid-cols-2 gap-6 md:grid-cols-5 mt-6 md:mt-24 md:max-w-6xl m-auto">
            <Link to="#server" className="flex flex-col justify-center text-center">
              <Ui.Image filename="server-p-icon.png" alt="Server backup" className="mx-auto w-[70px]" style={{'width': '70px'}}/>
              <span className="block mt-3 font-bold">Servers & Folders<i className="fal fa-arrow-right text-sm ml-2"></i></span>
            </Link>
            <Link to="#database" className="flex flex-col justify-center text-center">
              <Ui.Image filename="db-p-icon.png" alt="Server backup" className="mx-auto w-[70px]" style={{'width': '70px'}}/>
              <span to="#database" className="block mt-3 font-bold">Databases<i className="fal fa-arrow-right text-sm ml-2"></i></span>
            </Link>
            <Link to="#server-volume" className="flex flex-col justify-center text-center">
              <Ui.Image filename="snapshot-p-icon.png" alt="Server backup" className="mx-auto w-[70px]" style={{'width': '70px'}}/>
              <span to="#server-volume" className="block mt-3 font-bold">Servers & Volumes<i className="fal fa-arrow-right text-sm ml-2"></i></span>
            </Link>
            <Link to="#storage" className="flex flex-col justify-center text-center">
              <Ui.Image filename="storage-p-icon.png" alt="Server backup" className="mx-auto w-[70px]" style={{'width': '70px'}}/>
              <span to="#storage" className="block mt-3 font-bold">Storage<i className="fal fa-arrow-right text-sm ml-2"></i></span>
            </Link>
            <Link to="#saas" className="flex flex-col justify-center text-center">
              <Ui.Image filename="saas-p-icon.png" alt="Server backup" className="mx-auto w-[70px]" style={{'width': '70px'}}/>
              <span to="#saas" className="block mt-3 font-bold">SaaS Apps<i className="fal fa-arrow-right text-sm ml-2"></i></span>
            </Link>
          </div>
          <div className="flex justify-center mt-16">
            <Ui.Link arrow="right" to="/features">View all features</Ui.Link>
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section >
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center"><span>Feature comparison</span></Ui.Typography>
          <div className="mt-6 md:max-w-4xl m-auto text-center">
            <table className="table-fixed w-full border-gray-50">
              <thead className="pb-3">
                <tr>
                <th className="w-1/3"></th>
                <th className="w-1/3 p-4 border-b border-l">
                  <div className="flex justify-center"><Ui.Image filename="simplebackups-logo.png"
                                                                 alt="simplebackups backups"
                                                                 style={{width: 120, maxWidth: 120}}/></div>
                </th>
                <th className="w-1/3 p-4 border-b border-l">
                  <div className="flex justify-center">BackupSheep</div>
                </th>
                <th></th>
              </tr>
              </thead>
              <tbody className="text-base">
              <tr>
                <td className="font-medium text-left p-3 border-b">File Backup</td>
                <td className="border-b border-l"><i className="fa fa-check text-green"/></td>
                <td className="border-b border-l"><i className="fa fa-check text-green"></i></td>
              </tr>
              <tr>
                <td className="font-medium text-left p-3 border-b">Database Backup</td>
                <td className="border-b border-l"><i className="fa fa-check text-green"/></td>
                <td className="border-b border-l"><i className="fa fa-check text-green"></i></td>
              </tr>
              <tr>
                <td className="font-medium text-left p-3 border-b">Server & Volume Snapshot</td>
                <td className="border-b border-l"><i className="fa fa-check text-green"/></td>
                <td className="border-b border-l"><i className="fa fa-check text-green"></i></td>
              </tr>
              <tr>
                <td className="font-medium text-left p-3 border-b">Storage Replication</td>
                <td className="border-b border-l"><i className="fa fa-check text-green"/></td>
                <td className="border-b border-l"><i className="fa fa-times text-red"></i></td>
              </tr>
              <tr>
                <td className="font-medium text-left p-3 border-b">SaaS Application Backup</td>
                <td className="border-b border-l"><i className="fa fa-check text-green"/></td>
                <td className="border-b border-l"><i className="fa fa-check text-green"></i></td>
              </tr>
              <tr>
                <td className="font-medium text-left p-3 border-b">Offsite storage (bring your own)</td>
                <td className="border-b border-l"><i className="fa fa-check text-green"/></td>
                <td className="border-b border-l"><i className="fa fa-check text-green"></i></td>
              </tr>
              <tr>
                <td className="font-medium text-left p-3 border-b">Included storage</td>
                <td className="border-b border-l"><i className="fa fa-check text-green"/></td>
                <td className="border-b border-l"><i className="fa fa-times text-red"></i></td>
              </tr>
              <tr>
                <td className="font-medium text-left p-3 border-b">Flexible Scheduling</td>
                <td className="border-b border-l"><i className="fa fa-check text-green"></i></td>
                <td className="border-b border-l"><i className="fa fa-check text-green"></i></td>
              </tr>
              <tr>
                <td className="font-medium text-left p-3 border-b">Advanced Notifications</td>
                <td className="border-b border-l"><i className="fa fa-check text-green"></i></td>
                <td className="border-b border-l"><i className="fa fa-check text-green"></i></td>
              </tr>
              <tr>
                <td className="font-medium text-left p-3 border-b">Robust API</td>
                <td className="border-b border-l"><i className="fa fa-check text-green"></i></td>
                <td className="border-b border-l"><i className="fa fa-check text-green"></i></td>
              </tr>
              <tr>
                <td className="font-medium text-left p-3 border-b">EU-based & GDPR compliant</td>
                <td className="border-b border-l"><i className="fa fa-check text-green"></i></td>
                <td className="border-b border-l"><i className="fa fa-times text-red"></i></td>
              </tr>
              </tbody>

            </table>
            <p className="text-center max-w-3xl text-lg mt-8 mx-auto">
              <span className="font-bold">Our conclusion</span>.
              The team behind BackupSheep is very talented and we're sure they will continue to build great products.
              Migrating to SimpleBackups will be a breeze and we're here to help you with that.
              SimpleBackups is a mature product loved for its features, support, stability and we're confident you'll love it.
            </p>
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="alt">
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center"><span>Migrate quickly & painleslly</span></Ui.Typography>
          <div className="mt-6 m-auto text-center">
            <div className="grid md:grid-cols-3 gap-16 text-left mt-20">
              <div>
                <i className="fad fa-user-ninja text-primary text-4xl" />
                <h3 className="text-xl font-bold mt-4 mb-1">Support Ninjas</h3>
                <p className="text-base">Our support team is ramped up to help BackupSheep users migrate their accounts on time.</p>
              </div>
              <div>
                <i className="fad fa-sparkles text-primary text-4xl" />
                <h3 className="text-xl font-bold mt-4 mb-1">Best-in-class UI</h3>
                <p className="text-base">95% of our users never have to reach out, we know you'll love our UI.</p>
              </div>
              <div>
                <i className="fad fa-terminal text-primary text-4xl" />
                <h3 className="text-xl font-bold mt-4 mb-1">Simple API</h3>
                <p className="text-base">A lot of backups to migrate? Leverage our API for an automated setup.</p>
              </div>
            </div>
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Sections.SectionTestimonialExtended />
    <Sections.SectionGetStarted/>


  </Layout>
)
export default AffiliatePage
