import AffiliatePage from "./landings/_alternative-to-backupsheep";

export default AffiliatePage

export const frontmatter = {
  pageMetaData: {
    refKey: "backupsheep",
    title: "Looking or a BackupSheep alternative?",
    description: "BackupSheep is closing. Keep all your backups running by moving to SimpleBackups. A better way of making backups.",
    author: "simplebackups.com"
  }
}